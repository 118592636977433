import "./styles.css";

var qr = document.getElementById("qr");
var shareButton = document.getElementById("share-button");

var show = false;

shareButton.onclick = function() {
  show = !show;

  if (show) {
    qr.className = "show";
  } else {
    qr.className = "";
  }
};
